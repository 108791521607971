@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding-top: 12rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-signout {
  @apply border border-gray-400 text-white py-2 px-4 rounded bg-transparent;
}

.nav-background {
  @apply bg-blue-800 text-white z-50;
}

.footer-background {
  @apply bg-blue-800 text-white p-4;
}

.bg-pending {
  background-color: rgba(252, 211, 77, 0.7); /* Amarillo diluido */
}

.bg-complete {
  background-color: rgba(16, 185, 129, 0.7); /* Verde diluido */
}

.bg-incomplete {
  background-color: rgba(239, 68, 68, 0.7); /* Rojo diluido */
}

html,
body,
#root {
  height: 100%;
}

.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.service-banner-title {
  max-width: 220px;
  white-space: normal;
}

.service-container {
  width: 400px;
  margin: 20px auto;
}

.service-banner-button {
  margin-top: 20px;
}

.service-title {
  font-size: 1rem;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.menu-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #282c34;
}

.menu-list li {
  list-style: none;
  margin: 10px 0;
}

.menu-list li a {
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.menu-list li a:hover,
.menu-list li a.active {
  background-color: #61dafb;
}

.second-menu-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.second-menu-list li {
  list-style: none;
  margin: 10px 0;
}

.second-menu-list li a {
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.second-menu-list li a:hover,
.second-menu-list li a.active {
  background-color: #61dafb;
}

.menu-container {
  display: none;
  flex-direction: column;
  align-items: center;
}

.menu-container.open {
  display: flex;
}

.second-menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media (min-width: 768px) {
  .nav-background {
    display: flex;
    align-items: flex-start; /* Cambiar align-items a flex-start para separar las filas */
    flex-direction: column; /* Asegurarse de que los menús estén en filas separadas */
  }

  .nav-background .menu-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .nav-background .menu-list {
    flex-direction: row;
    justify-content: center;
    background-color: transparent;
  }

  .nav-background .second-menu-list {
    flex-direction: row;
    justify-content: center;
    background-color: transparent;
  }

  .nav-background .flex.items-center {
    flex-direction: row;
    align-items: center;
  }

  .second-menu-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menu-container.open {
    display: flex;
  }
}

@media (max-width: 767px) {
  .menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .menu-list,
  .second-menu-list {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .menu-container.open .menu-list,
  .menu-container.open .second-menu-list {
    display: flex;
  }

  .menu-list li,
  .second-menu-list li {
    width: 100%;
  }

  .menu-list li a,
  .second-menu-list li a {
    width: 100%;
    text-align: center;
  }
}
